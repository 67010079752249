/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 11.04.2021
* @details TypeScript for Menu Component
*******************************************************************************/
import { Component, Vue } from 'vue-property-decorator';


@Component
export default class Menu extends Vue {

    mobileMenuActive = false;
    lastScrollPos = 0;

    created(): void {
        window.addEventListener('scroll', this.handleMobileMenuOnScroll);
    }

    mounted(): void {
        // Set header currently loaded content component
        this.setMenuHeaderForPageByPath(this.$router.currentRoute.path);

        this.$root.$on('removeMenuHeader', () => {
            this.removeMenuHeader();
        });
    }

    // Open and close mobile menu ob btn click
    toggleMobileMenu(): void {
        this.mobileMenuActive = !this.mobileMenuActive;
        if(this.mobileMenuActive) {
            window.removeEventListener('scroll', this.handleMobileMenuOnScroll);
        }
        else {
            window.addEventListener('scroll', this.handleMobileMenuOnScroll);
        }
    }

    // Fade up mobile menu on down scroll and fade down on up scroll
    handleMobileMenuOnScroll(): void {
        const currentScrollPos = window.pageYOffset;
        const mobileMenu = document.getElementById("mobileMenu");
        if(currentScrollPos > this.lastScrollPos) {
            if(mobileMenu != null) mobileMenu.style.top = "-4em";
        }
        else {
            if(mobileMenu != null) mobileMenu.style.top = "0";
        }
        this.lastScrollPos = currentScrollPos;
    }

    // Set style of menu header which is currently loaded in content
    setMenuHeaderForPage(targetID: string): void {

        for(let i = 1; i < 6; i++) {
            if(targetID === 'header' + i.toString()) {
                const targetHeader = document.getElementById(targetID);
                if(targetHeader != null) { targetHeader.className = 'open-span'; }
            }
            else {
                const header = document.getElementById('header' + i.toString());
                if(header != null) { header.className = ''; }
            }
        }
    }

    // Set style of menu header which is currently loaded by path
    setMenuHeaderForPageByPath(path: string): void {
        let header: string;
        switch (path) {
            case '/': header = 'header1'; break;
            case '/areas': header = 'header2'; break;
            case '/offers': header = 'header3'; break;
            case '/partners': header = 'header4'; break;
            case '/contact': header = 'header5'; break;
            default: header = ''; break;
        }
        this.setMenuHeaderForPage(header);
    }

    removeMenuHeader(): void {
        this.setMenuHeaderForPage('');
    }
}
