import { render, staticRenderFns } from "./Home.html?vue&type=template&id=4958485a&scoped=true&"
import script from "./Home.ts?vue&type=script&lang=ts&"
export * from "./Home.ts?vue&type=script&lang=ts&"
import style0 from "./Home.scss?vue&type=style&index=0&id=4958485a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4958485a",
  null
  
)

export default component.exports