import { render, staticRenderFns } from "./Impressum.html?vue&type=template&id=796ba49d&scoped=true&"
import script from "./Impressum.ts?vue&type=script&lang=ts&"
export * from "./Impressum.ts?vue&type=script&lang=ts&"
import style0 from "./Impressum.scss?vue&type=style&index=0&id=796ba49d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796ba49d",
  null
  
)

export default component.exports