/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 15.04.2021
* @details TypeScript for Footer Component
*******************************************************************************/
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {

    openImpressum(): void {
        this.$root.$emit('openImpressum');
    }
}
