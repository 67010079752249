/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 11.04.2021
* @details TypeScript for Home Component
*******************************************************************************/

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Home extends Vue {

    created(): void {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(): void {
        const consultingHeader = document.getElementById('consulting-header') as HTMLElement;
        const scopeCardsWrapper = document.getElementById('scopes-wrapper') as HTMLElement;
        const scopeCards = scopeCardsWrapper.childNodes;

        // Desktop
        if(window.innerWidth > 900) {
            if (consultingHeader != null && scopeCards != null) {
                const position = consultingHeader.getBoundingClientRect();

                // Undo mobile styles
                consultingHeader.style.borderBottom = '2px solid transparent';

                // Active styles
                if (position.top < 500) {
                    consultingHeader.style.margin = '30px 0 0 440px';
                    consultingHeader.style.borderBottom = '2px solid #a5c6f3';
                    consultingHeader.style.width = '26%';

                    for(let i = 0; i < scopeCards.length; i++) {
                        const scopeCard = scopeCards[i] as HTMLElement;
                        scopeCard.style.margin = '30px 25px 0 0';
                    }
                }
                // Inactive styles
                else {
                    consultingHeader.style.margin= '30px 0 0 15px';
                    consultingHeader.style.borderBottom = '2px solid transparent';
                    consultingHeader.style.width = '22%';

                    for(let i = 0; i < scopeCards.length; i++) {
                        const scopeCard = scopeCards[i] as HTMLElement;
                        scopeCard.style.margin = '10px 10px 0 0';
                    }
                }
            }
        }
        // Mobile
        else {
            if (consultingHeader != null && scopeCards != null) {
                const position = consultingHeader.getBoundingClientRect();

                // Undo desktop styles
                consultingHeader.style.width = 'auto';
                consultingHeader.style.borderBottom = 'none';

                for(let i = 0; i < scopeCards.length; i++) {
                    const scopeCard = scopeCards[i] as HTMLElement;
                    scopeCard.style.margin = '0';
                }

                // Active styles
                if (position.top < 500) {
                    consultingHeader.style.margin = '30px 20px 10px 20px';
                    consultingHeader.style.borderBottom = '2px solid transparent';
                }
                // Inactive styles
                else {
                    consultingHeader.style.margin = '30px 0 10px 0';
                    consultingHeader.style.borderBottom = '2px solid #a5c6f3';
                }
            }
        }
    }

}
