



















import { Component, Vue } from 'vue-property-decorator';
import Menu from './components/Menu/Menu.vue';
import Content from './components/Content/Content.vue';
import Footer from './components/Footer/Footer.vue';

@Component({
    components: {
        Menu,
        Content,
        Footer,
    },
})
export default class App extends Vue {

    created(): void {
        document.title = 'bAV Kaltenböck';
    }
}
