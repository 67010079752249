/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 14.08.2021
* @details TypeScript for Datenschutz Component
*******************************************************************************/
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Datenschutz extends Vue {

    mounted(): void {
        this.$root.$emit('removeMenuHeader');
    }
}
