/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 11.08.2021
* @details TypeScript for SocailmediaPinup Component
*******************************************************************************/

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SocialmediaPinup extends Vue {
}
