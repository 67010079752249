/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 11.04.2021
* @details TypeScript for Content Component
*******************************************************************************/

import { Component, Vue } from 'vue-property-decorator';

import SocialmediaPinup from '@/components/Content/SocialmediaPinup/SocialmediaPinup.vue';
import Impressum from '@/components/Content/Impressum/Impressum.vue';

@Component({
    components: {
        SocialmediaPinup,
        Impressum
    },
})
export default class Content extends Vue {
}
