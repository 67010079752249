/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 11.08.2021
* @details TypeScript for Impressum Component
*******************************************************************************/

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Impressum extends Vue {

    impressumIsOpen = false;

    mounted() : void {
        this.$root.$on('openImpressum', () => {
            const impressum = document.getElementById('impressum') as HTMLElement;
            impressum.style.left = '0px';
        });
    }

    close(): void {
        const impressum = document.getElementById('impressum') as HTMLElement;

        // Desktop
        if(window.innerWidth > 900) {
            impressum.style.left = '-500px';
        }
        // Mobile
        else {
            impressum.style.left = '-100%';
        }
    }
}
