import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/components/Content/Home/Home.vue';
import Areas from '@/components/Content/Areas/Areas.vue';
import Offers from '@/components/Content/Offers/Offers.vue';
import Partners from '@/components/Content/Partners/Partners.vue';
import Contact from '@/components/Content/Contact/Contact.vue';
import Datenschutz from '@/components/Content/Datenschutz/Datenschutz.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '*',
        name: 'Home',
        component: Home
    },
    {
        path: '/areas',
        name: 'areas',
        component: Areas
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers
    },
    {
        path: '/partners',
        name: 'partners',
        component: Partners
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: Datenschutz
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
