/*******************************************************************************
* @author  Daniel Kaltenböck
* @created 20.04.2021
* @details TypeScript for Partners Component
*******************************************************************************/
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Partners extends Vue {

}
